import SEO from "../components/seo";
import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import "../assets/css/main.css";

const PrivacyPolicy = () => (
  <Layout>
    <SEO
      seo={{
        metaTitle: "privacy Policy",
        metaDescription: "プライバシーポリシー",
      }}
    />
    <h1>プライバシーポリシー</h1>
    <small>公開日: 2022年3月23日</small>
    <br />
    <small>最終改定日: 2022年3月23日</small>
    <p>
      本プライバシーポリシーは、あにまるフレンズ
      （以下、当ブログと言います）の各種サービスにおけるお客様の個人情報の取り扱いの際に順守する方針を記したものです。
    </p>

    <h2>個人情報の利用目的について</h2>
    <p>
      当ブログでは、お問い合わせなどの際に、お名前やメールアドレスなどの個人情報を入力していただく場合がございます。
    </p>
    <p>
      取得した個人情報はお問い合わせなどの対応のみに用いるものとし、それ以外の目的には利用いたしません。
    </p>

    <h2>個人情報の第三者への開示について</h2>
    <p>
      当ブログで取得した個人情報は適切に管理し、以下の場合を除いて第三者に開示することはございません。
    </p>
    <ul>
      <li>ご本人様より同意が得られた場合</li>
      <li>法令により開示が求められた場合</li>
    </ul>

    <h2>広告について</h2>
    <p>
      当ブログでは、第三者配信の広告サービス（Googleアドセンス、A8.net、Amazonアソシエイト）を利用しており、ブログ内にこれらの広告が表示されることがあります。
    </p>
    <p>
      このようなサービスではお客様に適切な商品やサービスの広告を表示するため、Cookieを使用しております。
    </p>
    <p>
      Cookieを使用することで当ブログはお客様のアクセス履歴に基づいて広告を配信できるようになりますが、これはお客様個人を特定できるものではありません。
    </p>
    <p>
      また、Cookieの使用を望まれない場合はいつでもこの設定を無効にすることができます。
    </p>
    <p>
      Cookieを無効にする方法やGoogleアドセンスに関する詳細は「
      <a
        href="https://policies.google.com/technologies/ads?gl=jp"
        target="_blank"
        rel="noopener noreferrer"
      >
        広告 – ポリシーと規約 – Google
      </a>
      」をご確認ください。
    </p>
    <br />
    <p>
      Amazonのアソシエイトとして、あにまるフレンズ
      は適格販売により収入を得ています。
    </p>

    <h2>アクセス解析ツールについて</h2>
    <p>
      当ブログでは、アクセス解析ツールとして「Google
      アナリティクス」を使用しています。
    </p>
    <p>
      このツールではトラフィックデータの収集のため、Cookieを使用しております。
    </p>
    <p>
      トラフィックデータは匿名で収集されており、個人を特定するものではありません。
    </p>
    <p>
      また、Cookieの使用を望まれない場合はいつでもこの設定を無効にすることができます。
    </p>
    <p>
      「Googleアナリティクス」でデータが収集、処理される仕組みについての詳細は、
      <a
        href="https://www.google.com/intl/ja/policies/privacy/partners/"
        target="_blank"
        rel="noopener noreferrer"
      >
        こちらのページ
      </a>
      をご確認ください。
    </p>

    <h2>免責事項</h2>
    <p>当ブログの掲載内容によって生じた損害に対する一切の責任を負いません。</p>
    <p>
      当ブログはできる限り正確な情報を提供できるよう努めておりますが、正確性や安全性を保証するものではありません。
    </p>
    <p>
      また、リンク先の他のサイトで提供されている情報、サービスにおいても責任を負いかねますのでその点をご了承の上、ご利用ください。
    </p>

    <h2>著作権</h2>
    <p>
      当ブログに掲載されている文章・画像などのコンテンツの著作権は、運営者に属しています。
    </p>
    <p>法的に認められている引用の範囲を超えて、無断転載することを禁じます。</p>
    <p>
      また、当ブログでは細心の注意を払っておりますが、著作権、肖像権を侵害している場合は、お問い合わせフォームよりご連絡ください。
    </p>
    <p>速やかに対処させていただきます。</p>

    <h2>リンクについて</h2>
    <p>
      当ブログは基本的にリンクフリーですので、リンクを行う際の許可や連絡は不要です。
    </p>
    <p>
      ただし、インラインフレームの使用や画像、動画への直リンクはお控えください。
    </p>

    <h2>プライバシーポリシーの変更</h2>
    <p>
      当ブログは、個人情報に関する日本国の法令を遵守し、適宜本プライバシーポリシーの見直しに努めます。
    </p>
    <p>
      プライバシーポリシーが修正された場合、速やかに本ページにて公開されます。
    </p>

    <h2>その他</h2>
    <p>
      コンテンツの内容に誤りがあった場合は、お問い合わせフォームよりご連絡いただけますと幸いです。
    </p>
    <p>できる限り対応させていただきます。</p>
  </Layout>
);

export default PrivacyPolicy;
